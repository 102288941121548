/* site-v3.js */
if(typeof MGS == 'undefined') { MGS = {} };

MGS.common = {
  init : function() {
    this.initNavDropDowns();
    this.initCustomSelects();
    this.initTooltips();
    this.initModalLinks();
    this.initCarousels();
    this.initTabs();
    this.initSummaryDetails();
    this.initCollapse();
    this.initReadMore();
    this.initTagFilters();
    this.initCounters();
    this.initLiveData();
    this.initIntroAnimations();

    MGS.lead_magnets.init();

    if ($('.defer-load').length > 0)  this.deferLoad();

    MGS.email_signup_drip.init();

    // Init slide-in navigation for mobile
    $("#mobile-nav").mmenu({
      offCanvas: {
        position: "right",
      },
      extensions: ["theme-dark"],
      slidingSubmenus: true,
      navbar: {
        title: 'More...'
      }
    },{
      clone: false
    });

    $('a[data-toggle="search"]').click(function(e) {
      // only add class with transitions after opened once to avoid loading slide up
      $('#search').addClass('has-opened');
      // If scrolled at the top, the search icon acts as a toggle
      // but if scrolled down, the search icon always opens and scrolls to the top
      if($(window).scrollTop() == 0) {
        $('#search').toggleClass('is-open');
      } else {
        $('#search').addClass('is-open');
      }
      // e.preventDefault();
      // return false;
    });
    $('a[data-toggle="tags"]').click(function(e) {
      $('#search').toggleClass('show-tags');
      e.preventDefault();
      return false;
    });


    // sticky header
    $(window).scroll(function() {
      var scroll = $(window).scrollTop(),
          $body = $('body');

      if (scroll > 28) {
        $body.addClass('scrolled');
      } else {
        $body.removeClass('scrolled');
      }

      if (scroll > 50) {
        $body.addClass('scrolled-mobile');
      } else {
        $body.removeClass('scrolled-mobile');
      }
    });

    // run SVG polyfill
    svg4everybody();
  },
  finalize : function() {},

  initNavDropDowns : function() {
    // If on touch device, make the first tap reveal the nav, not go to the link
    if ( 'ontouchstart' in document.documentElement ) {
      $('#nav ul.primary > li').has('.primary-subnav').on('click', 'a', function(e) {
        var $menuItem = $(this);
        var $menuItemLI = $menuItem.parents('li');
        var target = $menuItem.attr('href');

        if ( !target || target === '#' ) {
          return false;
        }
        if ( $menuItemLI.is('.sfhover') ) {
          return true;
        }
        e.preventDefault();

        $('.sfhover').removeClass('sfhover');
        $menuItemLI.addClass('sfhover');

        if($menuItemLI.is('#search')) {
          $('#keywords').focus();
        }
      });
    }
    $('body').click(function(e) {
     if($('.sfhover').length > 0) {
       if($(e.target).closest('#nav').length > 0) return true;
       $('.sfhover').removeClass('sfhover');
     }
    });
  },

  initCarousels : function() {
    if($('.carousel').length > 0) {
      $('.carousel').slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplaySpeed: 7500,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    }

    if($('.carousel-narrow').length > 0) {
      $('.carousel-narrow').slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplaySpeed: 7500,
        slidesToShow: 2,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    }

    if($(".clients-carousel").length > 0) {
      $(".clients-carousel").slick({
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 6,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 4,
              arrows: false,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              arrows: false,
              dots: true
            }
          }
        ]
      });
    }

    if($(".testimonial-tab-carousel").length > 0) {
      $(".testimonial-tab-carousel").slick({
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        adaptiveHeight: true,
        asNavFor: ".testimonial-tab-nav-carousel"
      });
    }

    if($(".testimonial-tab-nav-carousel").length > 0) {
      $(".testimonial-tab-nav-carousel").slick({
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: ".testimonial-tab-carousel"
      });
    }
  },

  initModalLinks : function() {
    var searchParams = new URLSearchParams(document.location.search);

    $('.modal-link').modaal({
      // after_open: MGS.email_signup.init // re-initialize email forms that may be in modal
      before_open: function(e) {
        // Update the URL with currently selected classes
        if(e !== undefined) {
          let tgt = e.currentTarget.hash;
          searchParams.set("m", tgt);
          let updatedUrl = '?' + searchParams.toString().replaceAll('%23', '');
          history.replaceState({},'', updatedUrl);
        }
      },
      after_close: function() {
        searchParams.delete("m");
        let updatedUrl = '?' + searchParams.toString();
        history.replaceState({}, '', updatedUrl);
      }
    });

    $('.modal-link-video').modaal({
      type: 'video'
    });

    $('.modal-link-iframe').modaal({
      type: 'iframe',
      width: '70%',
      height: 600
    });

    $('.modal-link-gallery').modaal({
      type: 'image'
    });

    $('.close-modal').click(function() {
      // loop through and close ALL modals
      $('.modal-link').each(function() {
        $(this).modaal('close');
      });
    });

    // Review URL to open any modals automatically
    // Check ?m=modalId
    if (searchParams.get('m')) {
      if ($('.modal-link[href="#' + searchParams.get('m') + '"]').length > 0) {
        $('.modal-link[href="#' + searchParams.get('m') + '"]').first().modaal('open');
      }
    }
    

  },

  initTooltips : function () {
    $tooltips = $('.has-tooltip[title]').add('.has-tooltip[data-tooltip-el]');
    if($tooltips.length == 0) return;

    $tooltips.each(function() {
      var ttOpts = { maxWidth: 300, interactive: true };
      var $this = $(this);

      if($this.data('tooltip-el')) {
        var ttEl = '#' + $this.data('tooltip-el');
        ttOpts.content = $(ttEl).html();
      }

      $this.tooltipster(ttOpts);
    });;
  },

  initTabs : function() {
    $('.tabs, .htabs, .vtabs, .contact-tabs, .iconcircle-tabs').each(function() {
      var defaultTab = $(this).data('default'),
          firstTab = $(this).find('li:first-child > a');

      // Check if URLs or if IDs. Only initiate if IDs
      if(typeof firstTab.attr('href') !== 'undefined') {
        if (firstTab.attr('href').substring(0,1) != '#') return;
      }

      $(this).find('li > a').on('click', function(e) {
        e.preventDefault();
        $(this).tab('show');
      });

      // Preselect a tab: default to first tab
      var selectTabId = firstTab.attr('href');
      var searchParams = new URLSearchParams(document.location.search);

      // Check ?tab=tabId
      if (searchParams.get('tab')) {
        if ($(this).find('a[href="#' + searchParams.get('tab') + '"]').length > 0) {
          selectTabId = '#' + searchParams.get('tab');
        }

      // Or, check url.html#tabId
      } else if(window.location.hash != '') {
        if ($(this).find('a[href="' + window.location.hash + '"]').length > 0) {
          selectTabId = window.location.hash;
          setTimeout(function() {
            window.scrollTo(0, 0);
          }, 1);
        }

      // Or, if 
      } else if (defaultTab) {
        selectTabId = '#' + defaultTab;

      // Or, first tab's data-target="tabId"
      } else if (typeof firstTab.data('target') !== 'undefined') {
        selectTabId = firstTab.data('target');
      }

      $(this).find('a[href="' + selectTabId + '"]').tab('show');
    });

    $(document).on('click', '[data-toggle="tab"]', function(e) {
      e.preventDefault();
      $(this).tab('show');
    });
  },

  initCustomSelects : function() {
    if ($('.custom-selectbox').length > 0) {
      var selectBox = $("select.custom-selectbox").selectBoxIt();
    }
    if($('.chosen-select').length > 0) {
      $('.chosen-select').chosen();
    }
  },

  initIntroAnimations : function() {
    // When elements become visible, add the "go" class to trigger the intro animation
    $('[class*="introanim"]').each(function() {
      $(this).waypoint(function(direction) {
        $(this.element).addClass('go');
      }, {
        offset: '90%'
      });
    });
  },

  initTagFilters : function(currentTags,contentType) {
    if($('#filter-tags').length > 0) {

      // Get currentTags and contentType from URL segments
      var currentURL = $(location).attr('href').split("?")[0].split("/");
      var currentTags = (currentURL.length < 6) ? '' : currentURL[5];
      var contentType = currentURL[3];

      // Trigger slidedown filters
      if($('#filter-tags-trigger').length > 0) {
        $('#filter-tags-trigger').on('click', function() {
          var filterTarget = '#' + $(this).data('filter');
          if($(filterTarget).hasClass('is-open')) {
            // Is open so close
            $(filterTarget).css({ 'overflow' : 'hidden' }).removeClass('is-open');
          } else {
            // Is closed so open
            $(filterTarget).addClass('is-open').delay(700).queue(function (overflow) {
              $(this).css({ 'overflow' : 'visible'});
              overflow();
            });
          }
        });
      }

      // Test for initialiation of chosen select box
      $('#filter-tags').on('chosen:ready', function(evt, params) {
        $(this).parents('form').addClass('is-chosen-ready');
      });

      if(currentTags != '') {
        var currentTagsArray = currentTags.split(',');
        $.each(currentTagsArray, function(index, value) {
          var x = $('option[value=' + value + ']').attr('selected', 'selected');
        });
      }

      $("#filter-tags").trigger("chosen:ready");
      $("#filter-tags").trigger("chosen:updated");

      $('#filter-tags').change(function() {
        $(this).parents('form').addClass('is-changed');
      });
      $('#filter-sort').change(function() {
        $(this).parents('form').addClass('is-changed');
      })

      $('.filter-tags-form').submit(function(e) {
        var tagsUrlList = ($('#filter-tags').val()) ? 'tag/' + $('#filter-tags').val().toString() : '';
        var newUrl = "/" + contentType;
        if(tagsUrlList != '') {
          newUrl = newUrl + "/" + tagsUrlList;
        }
        if($('#filter-sort').length > 0) {
          newUrl = newUrl + '?orderby=' + $('#filter-sort').val();
        }
        window.location.href = newUrl;
        e.preventDefault();
      });
    }
  },

  play_video : function(url) {
    window.open(url, 'PlayVideo', 'width=867,height=488,directories=0,status=0,menubar=0,location=0,toolbar=0,resizable=0,scrollbars=0');
  },

  initCounters : function() {
    $counters = $('.counter');
    if($counters.length == 0) return;

    $counters.each(function() {
      var $counter = $(this),
          $counterTarget = $('#' + $counter.data('counter-target')),
          counterMax = $counter.data('counter-max');

      $counter.text(counterMax - $counterTarget.text().length);

      $counterTarget.bind('input propertychange', function() {
        var remaining = counterMax - $(this).val().length;
        $counter.text(remaining);
        if(remaining < 15) {
          $counter.addClass('counter-alert');
        } else {
          $counter.removeClass('counter-alert');
        }
      });
    });
  },

  mgsGet : function(url, args) {
    var jqxhr = $.ajax({
      url:       url,
      type:     'GET',
      dataType: "html",
      data:     args
    })
    .error(function( xhr, errorType, exception ) {
      console.log('Ajax GET Error: ' + exception, 'mgsGet' );
    });
    // console.log("jqxhr: [ " + jqxhr + " ]");
    return jqxhr;
  },

  mgsPost : function(url, args) {
    var jqxhr = $.ajax({
      url:       url,
      type:     'POST',
      dataType: "html",
      data:     args
    })
    .error(function( xhr, errorType, exception ) {
      // console.log('Ajax POST Error: ' + exception, 'mgsPost' );
    });
    return jqxhr;
  },


  // Replace a SPAN with data from a JSON feed
  // ie. <span data-replace="weekly_tips_count">123</span>
  // options include:
  // weekly_tips_count
  // participations_couint
  // evaluations_count
  initLiveData : function() {
    $.getJSON("/site/stats").done(function(data) {
      $.each(data, function(key, val) {
        // add commas to number
        var v = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Find an item on the page using this var and replace it
        $('[data-replace="' + key + '"]').text(v);
      });
    });
  },

  deferLoad : function() {
    $('.defer-load').each(function() {
      var contentURL = $(this).data('content-url');
      $(this).load(contentURL);
    });
  },

  initCollapse : function() {
    $(document).on('click', '[data-toggle="collapse"]', function(e) {
      e.preventDefault();
      var $target = $($(this).data('target'));
      $target.toggleClass("show");
      $(this).attr('aria-expanded', $target.is('.show'));
    });
  },

  initReadMore : function() {
    $(document).on('click', '[data-toggle="readmore"]', function(e) {
      e.preventDefault();
      var $target = $($(this).data('target'));
      $target.removeClass('hidden').show();
      $(this).hide();
    });
  },

  initSummaryDetails : function() {
    $(document).on('click', '[data-toggle="details"]', function(e) {
      e.preventDefault();

      let elTarget = this.dataset.target;
      let allTargets = (elTarget == 'all') ? document.querySelectorAll('details') : document.querySelectorAll(elTarget);

      allTargets.forEach(item => {
        if(this.dataset.direction == 'open') {
          item.setAttribute('open', '');
        } else {
          item.removeAttribute('open');
        }
      });

    });
    
  },

};



// Blog
// ----------------------------------------------------------------

MGS.blog = {
  init : function() {
    $('.print-link').click(function(e) {
      window.print();
      e.preventDefault();
    });

    this.repositionAds();
  },

  repositionAds : function() {
    // Ads inserted into blog posts appear too close to illustrations, reposition them down several paragraphs
    var $ad = $('.ad-reposition'),
        $content = $('.entry-content');

    if($ad.length) {
      $ad.detach();
      $content.find('> p:nth-of-type(4)').prepend($ad);
    }
  }
};


// Articles
// ----------------------------------------------------------------

MGS.articles = {
  init : function() {
    MGS.blog.init();
  }
};


// Presentations
// ----------------------------------------------------------------

MGS.presentations = {
  init : function() {
    // If #video in URL, automatically open the modal
    if (window.location.hash == '#video') {
      $('#presentation-video-link').modaal('open');

    // If #video in URL, automatically open the modal
    } else if (window.location.hash == '#slides') {
      $('#presentation-slides-link').modaal('open');
    }
  }
};


// Training Schedule
// ----------------------------------------------------------------

MGS.training_schedule = {
  init : function() {
    $('.filters select[data-filter]').on('change',this.filterCourses);
  },

  filterCourses : function(e) {
    var filters = e.target.closest('.filters');
    if (filters.dataset.target == 'upcoming-courses') {
      var table = '.upcoming-training-table-container';
    } else {
      var table = '#' + filters.dataset.target;
    }
    var select = e.target;

    var course = filters.querySelector('[data-filter="course"]').value.trim(),
        instructor = filters.querySelector('[data-filter="instructor"]').value.trim(),
        $rows  = $(table).find('tr'),
        $weeks = $(table).find('.upcoming-training-weeks'),
        filter = '';

    $('#no-results').hide();
    $('.instructions').show();

    // Build Filters
    if(course != 'all') {
      filter += '[data-filter-course="' + course + '"]';
    }
    if(instructor != 'all') {
      filter += '[data-filter-instructor="' + instructor + '"]';
    }

    if(filter != '') {
      $rows.hide().filter(filter).show();
    } else {
      $rows.show();
    }

    // If week has no visible rows, hide the whole week, else ensure it's visible
    $weeks.show();
    $weeks.each(function() {
      if ($(this).find('tr:visible').length == 0) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });

    // Check for no results
    if($rows.filter(':visible').length == 0) {
      $('#no-results').show();
      $('.instructions').hide();
    }
  }
};


// Training Locations
// ----------------------------------------------------------------

MGS.locations = {
  init : function() {
    if(typeof google !== "undefined" && typeof google.maps !== "undefined") {
      google.maps.event.addListenerOnce(gmap_map, 'idle', function(){
        // re-initialize modal links once map is loaded to ensure links inside infoboxes work
        MGS.common.initModalLinks();
      });
    }
  }
};


// Course
// ----------------------------------------------------------------
MGS.course = {
  init : function() {
    $('.course-testimonials').each(function() {
      var $avatarsSlider = $(this).find('.testimonial-avatars-slider');
      var $testimonialsSlider = $(this).find('.testimonials-slider');

      if($avatarsSlider.length > 0) {
        $avatarsSlider.slick({
          arrows: false,
          dots: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          focusOnSelect: true,
          asNavFor: '#' + $testimonialsSlider.attr('id')
        });
      }

      if($testimonialsSlider.length > 0) {
        $testimonialsSlider.slick({
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          adaptiveHeight: true,
          asNavFor: '#' + $avatarsSlider.attr('id')
        });
      }
    });
  }
};



// Course Detail
// ----------------------------------------------------------------
MGS.course_detail = {
  init : function() {
    google.maps.event.addListenerOnce(gmap_map, 'idle', function(){
      // re-initialize modal links once map is loaded to ensure links inside infoboxes work
      MGS.common.initModalLinks();
    });
  }
};



// PDUs
// ----------------------------------------------------------------

MGS.pdus = {
  init : function () {
    $('.elearning-video-player-link').click(function(event) {
      MGS.common.play_video(this.href)
      event.preventDefault();
      event.stopPropagation();
    });
  }
};


// Tools
// ----------------------------------------------------------------

MGS.tools = {
  init : function() {
    if(MGS.pageInit) return false;
    MGS.pageInit = true;
    if($('.show-tool-instructions').length) {
      $('.show-tool-instructions').click(function() {
        $('.tool-instructions').toggle();
        $btn = $('.show-tool-instructions');
        var toggleLabel = $btn.text() == 'Show Instructions' ? 'Hide Instructions' : 'Show Instructions';
        $btn.text(toggleLabel);
      });
    }
  }
};


// Email Signup
// ----------------------------------------------------------------

MGS.email_signup_drip = {
  init : function() {
    if($('.drip-signup-form').length > 0) MGS.email_signup_drip.initDripSignup();
    if($('.drip-preferences-form').length > 0) MGS.email_signup_drip.initDripPreferences();
    if($('.drip-promotion-banner').length > 0) MGS.email_signup_drip.initPromotion();
  },

  // Add event to drip signup
  initDripSignup : function() {
    $('body').on('submit', '.drip-signup-form', function(e) {
      var $form = $(e.target),
          email = $form.find('input[name="fields[email]"]').val(),
          first_name = $form.find('input[name="fields[first_name]"]').val(),
          lead_name = $form.find('input[name="fields[last_lead_magnet_requested_url_title]"]').val(),
          tracker = ($form.data('tracker')) ? $form.data('tracker') : '';

      // This pushes to the GTM data layer which is picked up by analytics
      var leadCapturedEvent = {
        'event' : 'leadCaptured',
        'email' : email,
        'first_name' : first_name,
        'lead_name' : lead_name,
        'tracker': tracker
      };
      window.dataLayer.push(leadCapturedEvent);

      MGS.lead_magnets.setDripCookie();
    });
  },

  initDripPreferences : function() {
    if (typeof _dcq !== 'undefined') {
      _dcq.push(["identify", {
        success: function(data) {
          var customFields = data.custom_fields;
          customFields.email = data.email;

          // Loop through custom fields and update the form values
          for (var key in customFields) {
            var $input = $('input#drip-' + key);

            if($input.attr('type') == "checkbox") {
              var checked = (customFields[key] == "true") ? true : false;
              $input.prop('checked', checked);
            } else {
              $input.val(customFields[key]);
            }
          }
        }
      }]);
    } else {
      console.log("no drip");
    }

    $('.unsubscribe-button').on('click', function(e) {
      e.preventDefault();
      $form = $('.drip-preferences-form');
      $form.find('input:checked').prop('checked', false);
      if($('#drip-first_name').val() != '' && $('#drip-email').val() != '') {
        $form.submit();
      } else {
        if($form.find('.error-explanation').length) {
          $form.find('.error-explanation').text('Please fill out your first name and email address.');
        } else {
          $(this).parents('p').after('<div class="error-explanation">Please fill out your first name and email address</div>')
        }
      }
    });
  },

  initPromotion : function() {
    // Run blank identify to retrieve current data
    if (typeof _dcq !== 'undefined') {
      var dripEmail = UTIL.getUrlParameter('drip_email');

      // Ensure account vars are setup
      _dcs.account = '3499648';

      _dcq.push(["identify", {
        email: dripEmail,
        success: function(dripData) {
          var promotion = dripData.custom_fields.last_lead_magnet_requested_url_title;
          console.log("getting " + promotion);

          $.get('/email/promotion-banner/' + promotion)
            .done(function(promoData) {
              $('.drip-promotion-banner').replaceWith(promoData);
            });
        }
      }]);
    } else {
      console.log("no drip");
    }
  }
}


// Lead Magnets
// ----------------------------------------------------------------

MGS.lead_magnets = {
  init : function() {
    MGS.lead_magnets.inject();
    if($('.promo-lead-magnet').length) {
      MGS.lead_magnets.relocate();

      // If user is already logged in, just link to the promotion
      _dcq.push(["identify", {
        success: function(data) {
          if(typeof(data.email) !== 'undefined' && data.email != '') {
            MGS.lead_magnets.setDripCookie();
            MGS.lead_magnets.skipSignupForm(data);
          } else {
            MGS.lead_magnets.removeDripCookie();
          }
        }
      }]);
    }
  },

  // Set Drip cookie to remember
  setDripCookie : function() {
    $.cookie('dripIdentified', 'true', { expires: 120, path: '/'});
  },

  // Remove Drip cookie if user is logged out
  removeDripCookie : function() {
    $.removeCookie('dripIdentified');
  },

  // Inject lead-magnets into a page
  // ie. <div data-replace="lead-magnet"
  //          data-lead-magnet="url_title"
  //          data-style="additional-styles"
  //          data-class="additional-classname(s)"
  //          data-tracker="trackerID">
  //            Alternate Heading Goes Here
  //     
  inject : function() {
    $('[data-replace="lead-magnet"]').each(function() {
      var $el = $(this),
          url = '/site/lead-magnet-standalone/' + $el.data('lead-magnet'),
          altHeading = $el.text(),
          copyClasses = $el.data('class'),
          copyStyles = $el.data('style'),
          copyTracker = $el.data('tracker');

      $.get(url)
        .done(function(data) {
          $el.html(data);
          if(copyClasses != '') $el.find('.promo-lead-magnet').addClass(copyClasses);
          if(copyStyles != '') $el.find('.promo-lead-magnet').attr('style', copyStyles);
          if(altHeading != '') $el.find('.promo-heading').text(altHeading);
          if(copyTracker != '')  $el.find('.drip-signup-form').data('tracker', copyTracker);

          // Reinitialize any newly added modal links
          MGS.common.initModalLinks();

          // Double check for skipping signup form
          _dcq.push(["identify", {
            success: function(data) {
              if(typeof(data.email) !== 'undefined' && data.email != '') {
                MGS.lead_magnets.setDripCookie();
                MGS.lead_magnets.skipSignupForm(data);
              } else {
                MGS.lead_magnets.removeDripCookie();
              }
            }
          }]);
        })
        .fail(function(data) {
          console.log("lead magnet failed");
          $el.addClass('hidden');
        });
    });
  },

  relocate : function() {
    $('.relocate-lead-magnet').each(function(index) {
      var $lm = $('.promo-lead-magnet').eq(index);
      if ($lm.parents('.banner-lead-magnet')) {
        return;
      }
      $(this).replaceWith($lm);
    });
  },

  trackSubmit : function(promotion, title) {
    var $form = $('.email-signup-form-' + promotion),
        first_name = $form.find('input[name="[subscription]first_name"]').val(),
        email = $form.find('input[name="[subscription]email"]').val();

    MGS.lead_magnets.trackLeadMagnetRequest(email, first_name, promotion, title);
  },

  trackLeadMagnetRequest : function(email, first_name, promotion, title) {

    // If no first name, add their email instead
    var fname = (first_name) ? first_name : email;

    // Identify User in Drip and send lead magnet event
    _dcq.push(["identify", {
      email: email,
      first_name: fname,
      last_lead_magnet_requested_url_title: promotion,
      tags: ['Requested the ' + title + ' lead magnet'],
      success: function() {
        // console.log("identify success!");
        window.location.href = '/email/promotion?drip_email=' + encodeURIComponent(email);
      },
      failure: function(error) {
        console.log("identify fail: " + error);
      }
    }]);
  },

  skipSignupForm : function(data) {
    $('.promo-lead-magnet:not(.skipped)').each(function() {
      var $this = $(this),
          promotion = $this.data('lm-promotion'),
          title = $this.data('lm-promotion-title'),
          $btn = $this.find('.lead-magnet-button');

      if($btn.length) {
        if ($this.hasClass('promo-lead-magnet-form')) {
          var btnLabel = $btn.val();
        } else {
          var btnLabel = $btn.text();
        }
  
        var newBtn = $('<a class="lead-magnet-button button button-full button-invert" href="#"></a>');
        newBtn.text(btnLabel)
              .click(function(e) {
                e.preventDefault();
                MGS.lead_magnets.trackLeadMagnetRequest(data.email, data.custom_fields.first_name, promotion, title);
              })
              .appendTo($this.find('.promo-action'));
  
        if ($this.hasClass('promo-lead-magnet-form')) {
          $this.find('.email-signup-form').remove();
        } else {
          $btn.remove();
        }

        // This has now been skipped
        $this.addClass('skipped');
      }

    });
  },

  showEUFollowUp : function() {
    // If already identified, hide the follow-up
    if($.cookie('dripIdentified') == 'true') {
      $('#eu_follow_up').hide();
    }
  }
}


// Landing Pages
// ----------------------------------------------------------------

MGS.expandingPanels = {
  init : function() {
    $(document).on('click', '.expanding-panel', function(e) {
      var $this = $(this),
          expClass = ($this.hasClass('expanded')) ? '' : 'expanded';

      // Remove all expanded panels, self+siblings
      $this.parent().find('.expanded').removeClass('expanded');

      // Toggle this panel
      $this.addClass(expClass);
    });
    $(document).on('click', '.expanding-panel a', function(e) {
      e.stopPropagation();
    });
  }
}


/* via http://paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/ */
UTIL = {

  fire : function(func,funcname, args){
    var namespace = MGS;  // indicate your obj literal namespace here
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function'){
      namespace[func][funcname](args);
    }
  },

  loadEvents : function(){
    var bodyId = document.body.id;

    // hit up common first.
    UTIL.fire('common');

    // do all the classes too.
    $.each(document.body.className.split(/\s+/),function(i,classnm){
      UTIL.fire(classnm);
      UTIL.fire(classnm,bodyId);
    });
    UTIL.fire('common','finalize');
  },

  getUrlParameter : function(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

};

// Utility jQuery Plugins
(function($) {

  // Toggle Content of a node
  $.fn.toggleContent = function(content_1, content_2) {
    content_2 = typeof(content_2) != 'undefined' ? content_2 : '';
    return this.each(function() {
      var $this = $(this);
      new_content = ($this.text() == content_1) ? content_2 : content_1;
      $this.text(new_content);
    });
  };

})(jQuery);

// kick it all off here
$(document).ready(UTIL.loadEvents);
